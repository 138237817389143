.div_body {
  /* width: 230mm; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  font-size: 12pt;
  /* background: rgb(204, 204, 204); */
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.main-page {
  /* width: 210mm; */
  min-height: 297mm;
  /* margin: 10mm auto; */
  background: white;
  /* box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5); */
}
.sub-page {
  padding: 1cm;
  height: 297mm;
}

.table_w100 {
  display: table;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.table_w100 > div {
  display: table-row;
}

.company_hearder {
  display: table;
  width: 100%;
  height: 15mm;
}

/*
My Css.
*/

.company_hearder > div {
  display: table-row;
}
.company_hearder > div > div {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  font-size: 24pt;
}
.company_hearder > div > div + div {
  display: table-cell;
  color: #004269;
  font-weight: bold;
}
.address_and_invoiceno_row {
  display: table-row;
}
.address_and_invoiceno_table {
  display: table;
  width: 100%;
  margin-top: 2mm;
}
.address_and_invoiceno_table > div {
  display: table-row;
}
.address_and_invoiceno_table > div > div {
  display: table-cell;
  width: 50%;
}
.address_table {
  display: table;
  font-size: 10pt;
}
.address_table > div {
  display: table-row;
}

.invoiceno_table {
  display: table;
  width: 80%;
  font-size: 10pt;
  float: right;
}
.invoiceno_table > div {
  display: table-row;
}
.invoiceno_table div > div {
  display: table-cell;
  width: 50%;
}
.invoiceno_table div div span {
  display: block;
  border: solid;
  border-color: rgb(179, 179, 179);
  border-width: 1px;
  margin: 3px;
  padding: 5px;
  text-align: left;
  width: 100%;
}
.invoiceno_cell {
  vertical-align: bottom;
  text-align: right;
}

/**
Attn row
**/
.attn_row {
  display: table-row;
}
.attn_table {
  display: table;
  width: 100%;
  margin-top: 3mm;
}
.attn_table > div {
  display: table-row;
}
.attn_table > div > div {
  display: table-cell;
}
.attn_table > div:first-child {
  width: 60%;
}
.attn_sub_table {
  display: table;
  width: 100%;
}
.attn_sub_table > div {
  display: table-row;
}
.attn_sub_table > div > div {
  display: table-cell;
}
.attn_sub_table_header {
  background-color: #004269;
  color: aliceblue;
  padding: 5px;
  font-size: 10pt;
}
.attn_sub_table_contect {
  display: block;
  border: solid;
  border-color: grey;
  border-width: 1px;
  height: 30mm;
  padding: 5px;
}
/* .attn_sub_table_contect span{
  font-size: 12pt;
} */
.attn_sub_table_middle_cell {
  display: block;
  width: 20px;
}

.items_row {
  display: table-row;
  height: 610px;
}

/**
Items
**/

/* .items_table{
  display: table;
  width: 100%;
  margin-top: 5mm;
  border-collapse: collapse;
  border: 1px;
}
.items_table > div{
  display: table-row;

}
.items_table > div >div{
  display: table-cell;
  padding: 5px;
  border:1px solid gray;
}

.items_table > div:first-child{
  background-color: #004269;
  color: aliceblue;
  font-size: 9pt;
} */
.items_table {
  width: 100%;
  margin-top: 5mm;
  border-collapse: collapse;
  border: 1px;
}

.items_table tbody td {
  padding: 5px;
  border: 1px solid gray;
}

.items_table thead td {
  background-color: #004269;
  color: aliceblue;
  font-size: 9pt;
  padding: 5px;
  border: 1px solid gray;
}
/* 
thanks_row
*/
.thanks_row {
  /* width: 85%;
  position: absolute; right: 7%; */
  text-align: center;
  display: table-row;
  /* bottom: 10px; */

}
.thanks_font {
  font-weight: bold;
  font-size: 16pt;
  width: 100%;
  text-align: center;
  margin-top: 5mm;
}
.thanks_notes {
  font-size: 11pt;
  text-align: center;
}
.company_address {
  text-align: center;
  font-size: 11pt;
}
/*
print media
*/

@page {
  size: A4;
  margin: 0;
}
@media print {
  .div_body,
  .div_body {
    width: 210mm;
    height: 297mm;
  }
  /* .main-page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  } */
}
