.my-table-row-selected{
    background: #f5f5f5;
}

.table-cell-magin-0 > td.ant-table-cell{
    padding:0 !important;
    vertical-align:top;

}

.table-cell-magin-0 > td.ant-table-cell > input {
    border:0 
}
.table-cell-magin-0 > td.ant-table-cell > textarea{
    border:0 
}
.table-cell-magin-0 > td.ant-table-cell > div.ant-input-number{
    border:0 ;
    width: 100%;
    
}